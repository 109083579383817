.container {
  position: relative;
  margin: 16px auto;
  display: flex;
  justify-content: center;
}

.canvas-container {
  max-width: 1400px;
  min-width: 312px;
  margin: 0 auto;
}

.body-container {
  background-color: #F0F0F0;
  height: 100%;
}

canvas {
  border: 1px solid black;
  cursor: crosshair;
  position: absolute;
  background-color: white;
}

#tooltip {
  opacity: 0;
  position: absolute;
  background-color: black;
  color: white;
  top: 0;
  left: 0;
}

canvas:hover #tooltip {
  opacity: 1;
}

.header {
  margin: 16px 0;
  font-weight: 600;
  font-size: 1.5rem;
  background-color: #F0F0F0;
  display: flex;
  justify-content: space-between;
}

.subheading {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 1rem;
}

.toolbox, .set-lines {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.set-lines {
  gap: 4px;
}

.toolbox-container {
  margin: 16px;
  padding: 16px;
}

.toolbox-container label, .display-container label {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 4px;
  display: inline-flex;
  align-items: center;
}

.toolbox input {
  width: 80px;
}

.toolbox .input-button {
  margin-left: 4px;
}

.clear-button {
  margin-bottom: 8px;
}

.submit-button {
  margin-right: 8px;
  background-color: green;
  color: white;
}

.submit-button:disabled {
  background-color: rgb(137, 151, 137);
}

.align-center {
  display: inline-flex;
  align-items: center;
}

.display-container {
  border: 1px solid hsl(210, 10%, 58%);
  margin: 16px;
  padding: 16px;
  background-color: white;
}

.display-container > div {
  margin-bottom: 16px;
}

.current-coordinates > span {
  display: inline-block;
  margin-right: 16px;
  width: 120px;
}

.error-message {
  font-size: 10px;
  text-transform: uppercase;
  color: red;
  font-weight: 600;
  margin-top: 4px;
  width: 280px;
}
