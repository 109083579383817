/* div.pill {
  display: inline-block;
} */

span.cancel {
  cursor: pointer;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #A0A0A0;
  color: white;
  line-height: 14px;
  text-align: center;
  margin-left: 4px;
}

